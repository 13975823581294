import React from 'react';
import { Layout } from '../../components';

const PrivacyPolicy = () => (
  <Layout pageTitle="Privacy and Cookie Policy">
    <section className="legal-section bg-dark">
      <div className="container">
        <div className="row">
          <div>
            <h1 className="text-white text-center">
              Privacy and Cookie Policy
            </h1>
            <div className="text-justify text-white-60 col-lg-10 mx-auto">
              <p className="font-italic text-white text-left">
                Last update on June 14, 2022.
              </p>
              <h3 className="text-white text-left">
                Protection of User's personal data
              </h3>
              <p>
                At Cobalt Hills Tech Ltd ("Company", "we", "us" or "our"), we
                are committed to affirming the principles of legal, fair and
                transparent processing of personal data in accordance with
                Regulation (EU) 2016/679 of the European Parliament and of the
                Council of 27 April 2016 on the Protection of natural persons
                with regard to the processing of personal data and on the free
                movement of such data, and repealing Directive 95/46/EC (General
                Data Protection Regulation) and the current laws of the Republic
                of Croatia governing the area of personal data protection.
              </p>
              <p>
                We are dedicated to the protection of personal data of our
                employees, customers, users, business partners, or other persons
                with whom we are cooperating. We collect only necessary, basic
                data that is necessary for the fulfilment of our obligations,
                regularly inform about the usage of collected data, and give you
                the option of choosing the way your personal data will be used
                including the decision whether or not you want your name removed
                from lists used for marketing campaigns. All user data is safely
                stored and is available only to employees who need this data to
                perform their work. All employees of Cobalt Hills Tech Ltd, and
                business partners are responsible for respecting the principles
                of privacy protection.
              </p>
              <p>
                You guarantee that the information you provide is accurate,
                complete and up-to-date. By accepting this Privacy and Cookie
                Policy, you confirm that you agree with the use of your personal
                data for the above-mentioned purposes. All communication related
                to the order process and conclusion of the sales contract is
                carried out via e-mail and/or telephone number that you provide.
              </p>
              <p>
                In the event of a dispute before the court, the court of actual
                jurisdiction is in Rijeka, Republic of Croatia, on whose
                territory the Company's headquarter is located.
              </p>

              <h3 className="text-white text-left">Data collection</h3>
              <p>
                Personal data is any data relating to an individual whose
                identity is known or can be determined. An identifiable
                individual is a person who can be identified directly or
                indirectly, especially with the help of identifiers such as a
                name, personal identification number, location data and network
                identifier. They can be identified with the help of one or more
                factors inherent in the physical, physiological, genetic,
                mental, economic, cultural or social identity of that
                individual.
              </p>
              <p>
                We collect information about you that allows us to ensure the
                smooth operation of our website and to provide you with the best
                possible experience on our website. Generally speaking, we
                distinguish between (a) personal data that you provide to us,
                (b) personal data that we collect automatically and (c) personal
                data that we receive from another source.
              </p>

              <h3 className="text-white text-left">
                Personal data that you give us
              </h3>
              <p>
                For example, we collect data that you provide when you visit our
                website, when you request our newsletter, or when you order one
                of our services - in such cases, you fill in certain fields or
                click on a certain button and thereby possibly provide us with
                your personal data. We also collect data through surveys, when
                you contact our customer service or when you participate in a
                prize game or competition that we organise.
              </p>
              <p>
                When you provide us with service feedback or complain about a
                particular service, it is also possible (or likely) that we will
                collect some personal information from you. We collect all this
                data when you give us your consent for such collection or for
                the reason that we need such data to fulfil our contractual
                obligation (for example when we have to respond to a specific
                complaint related to the service), and for the purposes of this
                Privacy and Cookie Policy, we consider them Personal data that
                you give us.
              </p>

              <h3 className="text-white text-left">Consent</h3>
              <p>
                Each user gives their Consent to the collection of their
                personal data. In the form where personal data is entered, a
                notice appears that the data is collected in a precisely defined
                scope, for a precisely defined purpose and where this data is
                stored. If the same data will be used for multiple purposes, we
                ask for clear consent for each of those purposes (boxes in which
                you can independently put a checkmark).
              </p>

              <h3 className="text-white text-left">
                Personal data that we collect automatically
              </h3>
              <p>
                There is also Personal Data that we collect automatically. This
                is data that we record when you use our websites or when you use
                some of our services. Whenever you use our websites or, for
                example, when you read one of our e-mails, certain information
                (which is not necessarily personal data) is created and
                automatically collected.
              </p>
              <p>
                We collect such data to pursue a legitimate interest, and for
                this Privacy and Cookie Policy, we consider them to be Personal
                Data that we collect automatically.
              </p>

              <h3 className="text-white text-left">Cookies</h3>
              <p>
                To improve the quality and constantly improve the user
                experience, this website saves small text files called cookies
                on your computer when you visit it. Through cookies, we collect
                information about the use of the site to improve the quality and
                constantly improve the user experience. Examples of information
                about the use of the site include the most visited and most
                viewed pages and links on our website, the number of completed
                forms, time spent on the page, the most popular keywords that
                lead users to our site, IP address, data of the device from
                which they access the site such as hardware settings, system
                activities, browser types, etc.
              </p>
              <p>
                The directive of the European Union dated May 26, 2012, obliges
                website owners to request user's consent before storing cookies.
                By clicking on the "I agree" button, you give your consent to
                the use of cookies. By clicking on the "See more about cookies"
                button, you can view the Privacy and Cookie Policy.
              </p>
              <p>
                We use cookies to be able to make further improvements to this
                site, to improve your browsing experience on this site and to
                make your user experience as positive as possible. We will not
                use cookies together with your personal data without your prior
                consent. Our data processing is based on the legal provisions of
                valid positive regulations.
              </p>
              <p>
                Cookies are small text files placed on your device to store
                data, which can be recalled by the web server in the domain that
                placed the “Cookie”. We use cookies for enabling online
                advertising, fighting fraud, analysing the performance of our
                services and for other legitimate purposes. We may also use web
                trackers to deliver cookies and collect usage and performance
                data. Our websites may include web companions, cookies, or
                similar technology from third-party service providers.
              </p>
              <p>
                If you wish to, you can block cookies and continue browsing the
                site, which may lead to the fact that some functions of the
                website can no longer be fully used. Under no circumstances will
                the people behind the data be identified or personal data be
                collected, following the Data Protection Regulation.
              </p>
              <p>
                To order service on our website, you should enable the use of
                cookies. If you do not enable cookies, you will still be able to
                use our pages, but certain functionalities may be disabled. Most
                web browsers enable the use of cookies in their settings, but
                you can change these settings in the settings of your web
                browser (on a computer, tablet, or other mobile devices).
              </p>
              <p>
                Cookies cannot damage your device. We never store personal
                information, such as your debit/credit card information, in
                cookies, but we do use the encrypted and anonymized information
                we collect from cookies to improve our website - for example, to
                detect and fix errors that sometimes occur on websites.
              </p>
              <p>
                Cookies are not computer programs, they cannot read other
                information stored on your device. They cannot be used to
                transmit computer viruses or to obtain user data such as e-mail
                addresses, etc. We may have an agreement with carefully selected
                and monitored suppliers who, in a small number of cases, may set
                cookies during your visit to our website, and use the same for
                re-marketing purposes - that is, to show you services based on
                your assumed interests and preferential selections. Please be
                sure to take into account that we are not and cannot be
                responsible for the content of websites that are not ours or
                that we do not control. Our cookies do not contain personal
                information, such as your first or last name, or payment
                information. If you want to limit, block or delete cookies from
                any website under our control, you can do so in your web browser
                settings. Every web browser is different, so we suggest you look
                for such options in the 'Help' section of your web browser. You
                must be aware that by doing so, you may cause certain
                functionalities of our website to not work. If you need more
                information about cookies, please visit&nbsp;
                <a
                  href="https://allaboutcookies.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  allaboutcookies.org
                </a>
                .
              </p>

              <h3 className="text-white text-left">Our use of re-marketing</h3>
              <p>
                When you visit our website, cookies are used to generate data
                that Google collects and processes. You can prevent this by
                downloading and installing the&nbsp;
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Analytics Opt-out Browser Add-on
                </a>
                .
              </p>
              <p>
                You can find more information about the processing of user data
                by Google Analytics in&nbsp;
                <a
                  href="https://support.google.com/analytics/answer/6004245"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Analytics' privacy policy
                </a>
                .
              </p>
              <p>
                There are currently several websites that can be used to disable
                the storage of cookies for different services. You can get more
                information at the following links:
                <ul>
                  <li>
                    <a
                      href="https://allaboutcookies.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      allaboutcookies.org
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youronlinechoices.eu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      youronlinechoices.eu
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youradchoices.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      youradchoices.com
                    </a>
                  </li>
                </ul>
              </p>

              <h3 className="text-white text-left">
                Personal data that we receive from another source
              </h3>
              <p>
                Finally, it is possible for us to receive personal data about
                you from another source. For example, we can receive information
                about you via 'social networks', in case you 'like' our page on
                Facebook or start following us on another 'social network'. Then
                we may receive personal data about you through those pages, and
                we will use them exclusively to achieve some legitimate
                interest, and for the purposes of this Privacy and Cookie
                Policy, we consider them as Personal data that we receive from
                another source.
              </p>

              <h3 className="text-white text-left">
                Privacy policy for the use of social networks - use of 'sharing'
                and 'liking'
              </h3>
              <p>
                If you 'share' content with friends or 'like' or 'follow' Cobalt
                Hills Tech Ltd via a social network that we use, for example,
                Facebook, it is possible that cookies will be sent to you from
                one of these websites. We have no control over the settings of
                these cookies, but we suggest you check the 'Privacy Policies'
                of these third-party websites.
              </p>
              <p>
                Facebook:
                <br />
                For more information on the purpose and scope of data
                collection, further processing and use of data by Facebook, as
                well as your related rights and setting options to protect your
                privacy, please see&nbsp;
                <a
                  href="https://www.facebook.com/policy.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook's privacy policy
                </a>
                .
              </p>
              <p>
                Instagram:
                <br />
                Further information on the scope and use of Instagram data and
                related rights can be found in&nbsp;
                <a
                  href="https://privacycenter.instagram.com/policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram's privacy policy
                </a>
                .
              </p>
              <p>
                LinkedIn:
                <br />
                Further information on the scope and use of LinkedIn data and
                related rights can be found in&nbsp;
                <a
                  href="https://www.linkedin.com/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn's privacy policy
                </a>
                .
              </p>
              <p>
                GitHub:
                <br />
                Further information on the scope and use of GitHub data and
                related rights can be found in&nbsp;
                <a
                  href="https://docs.github.com/en/site-policy/privacy-policies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub's privacy policy
                </a>
                .
              </p>

              <h3 className="text-white text-left">Data processing</h3>
              <p>
                We use the obtained user data for one or more of the following
                purposes:
                <ul>
                  <li>
                    To personalise the user experience (collected information
                    helps us better respond to the individual needs)
                  </li>
                  <li>
                    To improve our website (we constantly strive to improve the
                    offer of our website based on the feedback we receive from
                    our visitors)
                  </li>
                  <li>To establish the primary channel of communication</li>
                  <li>
                    To send occasional emails (concerning occasional news,
                    updates, information about services, etc.)
                  </li>
                </ul>
              </p>

              <h3 className="text-white text-left">
                To whom do we share information about you?
              </h3>
              <p>
                Only when it is necessary, we will share your personal data with
                the following categories of recipients:
                <ul>
                  <li>
                    Associates at Cobalt Hills Tech Ltd and related companies
                  </li>
                  <li>
                    Third parties who have information about your finances, such
                    as financial or credit institutions
                  </li>
                  <li>
                    Public-legal bodies, when, acting in good faith, we have
                    reason to believe that this is necessary to comply with a
                    legal obligation
                  </li>
                  <li>
                    Other service providers who provide a specific service on
                    our behalf, or possibly third-party IT service providers
                    with whom we have entered into appropriate contracts for the
                    processing of personal data (or there are other appropriate
                    mechanisms).
                  </li>
                </ul>
              </p>

              <h3 className="text-white text-left">Other websites</h3>
              <p>
                Our website may contain links to other websites. This Privacy
                and Cookie Policy applies only to websites managed by Cobalt
                Hills Tech Ltd, so when you click on a link that takes you to
                other websites, you should read their rules and privacy
                policies.
              </p>

              <h3 className="text-white text-left">Data protection</h3>
              <p>
                To protect the personal data, we receive through the website, we
                use physical, technical, and organisational security measures.
                We are constantly upgrading and testing our security technology.
                We limit access to personal information to only those employees
                who need to know that information to provide benefits or
                services.
              </p>
              <p>
                In addition, we educate our employees about the importance of
                data confidentiality and the preservation of privacy and the
                protection of your data.
              </p>
              <p>
                Personal data that is exchanged between you and us through our
                website is transmitted using the most modern connections. All
                used IT systems are protected against unauthorised access to
                security systems, and employees are obliged to keep data
                confidential. However, Cobalt Hills Tech Ltd has no effect on
                the transmission of data over the Internet and therefore cannot
                exclude misuse of third-party access.
              </p>

              <h3 className="text-white text-left">Individual rights</h3>
              <p>
                If you want to know whether we own and process your personal
                data, or if you want to access your personal data, you can
                contact us at{' '}
                <a href="mailto:dpo@cobalt-hills.tech">dpo@cobalt-hills.tech</a>
                .
              </p>
              <p>
                You may also demand the following information: the purpose of
                data processing, categories of personal data under processing,
                who else outside of our company has received personal data from
                us, who is the source of the personal data (if not directly
                provided to us) and how long we will keep the data. You have the
                right to correct the personal data we have if it is incorrect.
                You can also, with certain exceptions, request that we delete
                the data or stop processing it. You may ask us to stop using
                personal data for direct marketing purposes.
              </p>
              <p>
                If it is technically feasible, we will, based on the request,
                send personal data to you or transfer them directly to another
                data controller.
              </p>
              <p>
                If you submit a request to{' '}
                <a href="mailto:dpo@cobalt-hills.tech">dpo@cobalt-hills.tech</a>{' '}
                to send you your personal data, we will comply with that request
                within a reasonable period of 14 working days. If we cannot
                comply with the request within a reasonable time, we will inform
                you of the date when we will comply with the request. If for
                some reason we cannot fulfil that request, we will send an
                explanation of why we did not fulfil your request.
              </p>
              <p>
                Based on the General Data Protection Regulation, you have the
                following rights:
              </p>
              <p>
                <b>
                  <i>The right to object</i>
                </b>
              </p>
              <p>
                This right allows you to object to the processing of your
                personal data, in those cases where we process your personal
                data on one of the following legal bases:
                <ul>
                  <li>
                    because the processing of personal data is in our legitimate
                    interest
                  </li>
                  <li>to fulfil a contractual obligation</li>
                  <li>
                    for scientific, research, marketing, or statistical purposes
                  </li>
                </ul>
                In these circumstances, the processing of your personal data
                based on legitimate interest will most often be applied. If we
                are unable to demonstrate that we have a convincing and
                legitimate basis for processing your personal data, which goes
                beyond your rights and interests, we will stop processing your
                personal data.
              </p>

              <p>
                <b>
                  <i>The right to withdraw consent</i>
                </b>
              </p>
              <p>
                In cases where we have obtained your consent for certain
                actions, you are entitled to withdraw the given consent at any
                time and we will stop using your personal data for this purpose
                unless we believe that there is an alternative legal basis that
                would justify the further processing of your personal data for
                this purpose, in which case we will inform you about it.
              </p>

              <p>
                <b>
                  <i>The right of access</i>
                </b>
              </p>
              <p>
                You can also request information about the purpose of the data
                processing, the categories of personal data being processed, who
                else outside of our company has received personal data from us,
                and what is the source of the personal data (if you have not
                provided it directly to us) and how long we will keep the data.
              </p>
              <p>
                If it is technically feasible, we will send you personal data
                based on your request or transfer them directly to another data
                controller.
              </p>
              <p>
                At any time, you are authorised to ask us for a copy of the
                information we have about you, as well as to ask us to modify,
                update or delete this information. We may ask you for additional
                information about your request. If we provide you with access to
                the data we hold about you, we will do so free of charge, unless
                the processing of the request is manifestly unfounded or
                excessive. In case you request additional copies of this data
                from us, a proportionate administrative fee may be charged. Your
                request may be denied in cases where this is legally permitted.
              </p>

              <p>
                <b>
                  <i>The right to deletion</i>
                </b>
              </p>
              <p>
                In certain circumstances, you are authorised to request that we
                "delete" your personal data. For example, you have this right in
                the following cases:
                <ul>
                  <li>
                    We no longer need your data for the purpose for which it was
                    initially collected
                  </li>
                  <li>
                    You have withdrawn the consent you gave us to process your
                    personal data and there is no other legal basis for us to
                    continue processing your personal data (in cases where the
                    legal basis for processing personal data is Consent)
                  </li>
                  <li>Data was processed without a valid legal basis</li>
                  <li>
                    Deletion of data is necessary to comply with our legal
                    obligations
                  </li>
                  <li>
                    You have objected to the processing of personal data, and we
                    are unable to prove the existence of a legitimate legal
                    basis that exceeds your rights and interests, on the basis
                    of which we would be authorised to continue processing your
                    personal data
                  </li>
                </ul>
                We are authorised to reject your request for data deletion only
                in a limited number of cases, and in such cases, we will explain
                the reasons for the rejection in detail.
              </p>

              <p>
                <b>
                  <i>The right to rectification</i>
                </b>
              </p>
              <p>
                You have the right to correct the personal data we hold if it is
                incorrect. You can also, with certain exceptions, request that
                we delete the data or stop processing it. You can ask us to stop
                using personal data for direct marketing purposes.
              </p>
              <p>
                You have the right to request the correction of any inaccurate
                or incomplete information we have about you. In the event that
                we have shared your data with third parties, we will notify them
                of the correction, unless this is not possible or would cause
                disproportionate difficulties. You are also authorised to
                request information about third parties to whom we have provided
                incorrect or incomplete personal information. In the event that
                we consider that it is not reasonably possible to act on your
                request, we will inform you about this and we will explain the
                reasons for such a decision.
              </p>

              <p>
                <b>
                  <i>The right to transfer</i>
                </b>
              </p>
              <p>
                If you wish, you are authorised to request the transfer of your
                personal data to another data controller. This right actually
                means that it is possible to request that your personal data be
                transferred to a third party. In order to enable you to do this,
                we will transfer your data to you in the usual, machine-readable
                form so that you can transfer your personal data to a third
                party. This right is applicable if it concerns: (i) data that we
                process automatically (without a human factor); (ii) if it is
                about data that you have given us; and (iii) if it is data that
                we collect and process based on your consent or to fulfil the
                contract.
              </p>

              <h3 className="text-white text-left">
                Period of storage of your personal data
              </h3>
              <p>
                Following your rights as a data subject, we will store the data
                in accordance with the relevant regulations, whereby the storage
                periods may depend on the fulfilment of some of our obligations
                based on a certain regulation. In any case, when it is
                determined that we no longer need your data and that the legal
                deadlines for keeping such records and data have passed, we will
                delete your personal data.
              </p>

              <h3 className="text-white text-left">How to contact us</h3>
              <p>
                If you wish to exercise one of the aforementioned rights or
                withdraw your consent for the processing of your personal data
                (in cases where consent is the legal basis for the processing of
                your personal data), please contact us. Please note that we will
                keep a record of our communications so that we can resolve any
                disputes on time.
              </p>
              <p>
                You can contact us at any time in the following way:
                <ul>
                  <li>
                    Headquarter address: Cobalt Hills Tech Ltd, Pod vrh 7,
                    Cernik, 51219 Čavle
                  </li>
                  <li>
                    E-mail:{' '}
                    <a href="mailto:dpo@cobalt-hills.tech">
                      dpo@cobalt-hills.tech
                    </a>
                  </li>
                  <li>Phone: 00385 (0)98 918-2348</li>
                </ul>
              </p>

              <h3 className="text-white text-left">
                Notification of personal data breach
              </h3>
              <p>
                In the event of a personal data breach, we will notify those
                whose data is at risk and the competent supervisory authority by
                e-mail within 72 hours about the extent of the breach, the data
                covered, the possible impact on our services, and our planned
                measures to secure data and limit any adverse effects by
                individuals.
              </p>

              <h3 className="text-white text-left">
                Changes to our privacy policy
              </h3>
              <p>
                We reserve the right to change this Privacy and Cookie Policy in
                accordance with changes in legal provisions, business policies
                of the Company, or other circumstances that affect the area of
                data protection. In case of changes, we will inform you about it
                on the website and update the date of modification of the
                Privacy and Cookie Policy.
              </p>
              <p>
                We regularly review this Privacy and Cookie Policy and, if
                necessary, supplement them with additional information because
                we care about your privacy.
              </p>

              <h3 className="text-white text-left">
                The right to complain to the competent authority
              </h3>
              <p>
                At any time, you can send a complaint to the supervisory
                authority regarding the collection and processing of personal
                data. In the Republic of Croatia, you can file a complaint to
              </p>
              <p>
                Agency for the Protection of Personal Data (AZOP)
                <br />
                Selska cesta 136, 10 000 Zagreb, Croatia
                <br />
                Tel. 00385 (0)1 4609-000, Fax. 00385 (0)1 4609-099
                <br />
                E-mail: <a href="mailto:azop@azop.hr">azop@azop.hr</a>, Web:{' '}
                <a
                  href="https://www.azop.hr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.azop.hr
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrivacyPolicy;
